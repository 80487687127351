import { template as template_0d951e91bd9a4f4187ebab85a40c6d1a } from "@ember/template-compiler";
const SidebarSectionMessage = template_0d951e91bd9a4f4187ebab85a40c6d1a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
