import { template as template_8445a1839db64cbeaf4bbb3b2240fb2e } from "@ember/template-compiler";
const FKLabel = template_8445a1839db64cbeaf4bbb3b2240fb2e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
