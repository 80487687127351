import { template as template_9923ffe3141c42dea20e3e93b954b77f } from "@ember/template-compiler";
const FKControlMenuContainer = template_9923ffe3141c42dea20e3e93b954b77f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
